<template>
	<div>
		<f-default-header
			title="Marketing"
			subtitle="Newsletter - Email" />

		<f-card>
			<v-row>
				<v-col cols="8">
					<strong>{{newsletter.email}}</strong>
				</v-col>
				<v-col cols="4">
					<v-switch
						:disabled="false"
						hide-details
						v-model="newsletter.active"
						label="Ativo" />
				</v-col>
			</v-row>
		</f-card>

		<f-actions-btn-holder>
            <v-btn
                color="secondary"
                elevation="0"
                large
                @click="back"
                :disabled="false">

                cancelar
            </v-btn>
            &nbsp;
            <v-btn
                color="primary"
                elevation="0"
                large
                @click="save"
                :disabled="false">

                {{true?'SALVAR':'salvando'}}
            </v-btn>
        </f-actions-btn-holder>
	</div>
</template>

<script>


export default {
	data: () => ({
		id: null,
		newsletter: {}
	}),
	created() {
		this.id = this.$route.params.id
		this.getData()
	},
	methods: {
		async getData() {
            this.$store.dispatch( 'system/loading', 'data' )
            const newsletter = await this.$store.dispatch( 'mkt/newsletter_get', this.id  )
            this.$store.dispatch( 'system/loading', 'done' )

			this.newsletter = newsletter
        },
		async save(){
			this.$store.dispatch( 'system/loading', 'data' )
            await this.$store.dispatch(
				'mkt/newsletter_save',
				this.newsletter
			)
            this.$store.dispatch( 'system/loading', 'done' )
            this.back()
        },
        back(){
            this.$router.push({
                name: 'mkt-newsletter-list'
            })
        }
	}
}
</script>